<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body" :class="settings.status == 'Opened' || settings.status == 'Closed' ? 'p-10' : 'p-3'">
					<div class="row m-0 align-items-center">
						<div class="col">
                     <h6 class="limitador my-0 cursor-pointer" title="Edit" @click="editChannel">
                        <span>{{ $t("polls.selected") }}: <span class="ml-1" v-if="settings.nameChannel!=null">#{{ settings.nameChannel }}</span>
                        <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                     </h6>
						</div>
						<div class="w-max-content" v-if="settings.status == null || settings.status == 'Finished'">
                     <button class="btn btn-primary h-auto rounded-sm ml-2" @click="startPoll" v-if="settings.alternatives != null && settings.alternatives.length > 0"><i class="fal fa-check font-12 mr-1"></i> {{ $t("polls.start") }}</button>
						</div>
						<div class="w-max-content" v-else-if="settings.status == 'Opened'">
                     <button class="btn btn-primary h-auto rounded-sm ml-2" @click="closePoll">
                        <i class="fal fa-poll-h font-13 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("polls.close") }}</span>
                     </button>
                     <button class="btn btn-outline-primary h-auto rounded-sm ml-2" @click="getSettings">
                        <i class="fal fa-sync font-13 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("polls.update") }}</span>
                     </button>
                     <button class="btn btn-primary h-auto rounded-sm ml-2" @click="deletePoll">
                        <i class="fal fa-trash font-13 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("polls.delete") }}</span>
                     </button>
						</div>
						<div class="w-max-content" v-else-if="settings.status == 'Closed'">
                     <button class="btn btn-outline-primary h-auto rounded-sm ml-2" @click="endPoll">
                        <i class="fal fa-check font-13 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("polls.finish") }}</span>
                     </button>
                     <button class="btn btn-primary h-auto rounded-sm ml-2" @click="deletePoll">
                        <i class="fal fa-trash font-13 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("polls.delete") }}</span>
                     </button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-md-6 col-xl-4" v-if="preview.start != null">
         <div class="card mb-2">
            <div class="card-body p-10">
               <div class="row m-0 my-1 align-items-center">
                  <div class="col">
                     <h6 class="limitador my-0">{{ $t("polls.pollDesc") }}</h6>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.start.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.start)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                  </div>
               </div>
            </div>
         </div>
         <PreviewMessageDeck class="m-0 p-0" v-show="preview.start.enabled" :message="preview.start" :dadosUsuario="dadosUsuario" />
      </div>

      <div class="col-md-6 col-xl-4">
         <div class="card mb-0">
            <div class="card-body p-3">
               <div class="row m-0" v-if="settings.status == null || settings.status == 'Finished'">
                  <div class="col-12 mt-2">
                     <h6 class="limitador my-0">{{ $t("polls.alternatives.title") }}</h6>
                  </div>
                  <div class="col-12 mt-4">
                     <div class="cards">
                        <alternative v-for="(alternative, index) in settings.alternatives" :key="index" :alternative="alternative" :index="index" :view="false" 
                           @remove="deleteAlternative($event)" />
                     </div>
                     <div class="w-100 mt-3 d-sm-flex align-items-center justify-content-center">
                        <input type="text" class="w250 form-control mr-2" :placeholder="$t('polls.alternatives.label')" maxlength="200" @keyup.enter="addAlternative" />
                        <button type="button" class="btn btn-primary px-3" @click="addAlternative"><i class="far fa-plus"></i></button>
                     </div>
                  </div>
               </div>
               <div class="row m-0" v-else-if="settings.status == 'Opened'">
                  <div class="col-6 mt-2">
                     <h6 class="limitador my-0">{{ $t("polls.partial") }}</h6>
                  </div>
                  <div class="col-6 mt-2 text-right">
                     <h6 class="limitador my-0 weight-400 font-14">{{ $t("polls.result.total") }}: <span class="ml-1">{{ settings.totalVotes == null ? 0 : settings.totalVotes }}</span></h6>
                  </div>
                  <div class="col-12 mt-4">
                     <div class="cards">
                        <alternative v-for="(alternative, index) in settings.alternatives" :key="index" 
                           :alternative="alternative" :index="index" :totalVotes="settings.totalVotes" :view="true" />
                     </div>
                     <div class="text-center my-5" v-if="settings.alternatives == null || settings.alternatives.length == 0">{{ $t("polls.noAlt") }}</div>
                  </div>
               </div>
               <div class="row m-0" v-else-if="settings.status == 'Closed'">
                  <div class="col-6 mt-2">
                     <h6 class="limitador my-0">{{ $t("polls.result.title") }}</h6>
                  </div>
                  <div class="col-6 mt-2 text-right">
                     <h6 class="limitador my-0 weight-400 font-14">{{ $t("polls.result.total") }}: <span class="ml-1">{{ settings.totalVotes == null ? 0 : settings.totalVotes }}</span></h6>
                  </div>
                  <div class="col-12 mt-4">
                     <div class="cards">
                        <alternative v-for="(alternative, index) in settings.alternatives" :key="index" 
                           :alternative="alternative" :index="index" :totalVotes="settings.totalVotes" :view="true" />
                     </div>
                     <div class="text-center my-5" v-if="settings.alternatives == null || settings.alternatives.length == 0">{{ $t("polls.noAlt") }}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-md-6 col-xl-4" v-if="preview.end != null">
         <div class="card mb-2">
            <div class="card-body p-10">
               <div class="row m-0 my-1 align-items-center">
                  <div class="col">
                     <h6 class="limitador my-0">{{ $t("polls.result.title") }}</h6>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.end.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.end)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                  </div>
               </div>
            </div>
         </div>
         <PreviewMessageDeck class="m-0 p-0" v-show="preview.end.enabled" :message="preview.end" :dadosUsuario="dadosUsuario" />
      </div>

      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="settings.idChannel" @selectChannel="saveChannel($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import MessageDeck from '@/components/MessageDeck.vue'
import channel from '@/components/whitelist/Channel.vue'
import alternative from '@/components/poll/Alternative.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
   name: 'Poll',
   data: function () {
		return {
         message: null,
         settings: {"id": null, "idChannel": null, "nameChannel": null, "idServer": null, "alternatives": null, "status": null},
         preview: {'start': null, 'end': null}
      }
   },
   computed: {
		...mapState({
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   components: {
      channel, PreviewMessageDeck, MessageDeck, alternative
   },
   methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getsettingspoll',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data.preview;
            this.settings = response.data.settings;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannel : function () {
         $('#modalChannel').modal('show')
      },
      saveChannel : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idChannel = channel.id;
         this.settings.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelpoll',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            $('#modalChannel').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.saved")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		startPoll : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.polls.start"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/startpoll',
						headers: {
                     'Content-Type': 'application/json'
                  },
                  data: this.settings

					}).then(response => {
						this.settings = response.data;

						this.$toast.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.polls.started"),
                  })
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
                     } else if (error.response.status == 400) {
								this.$toast.fire({
									icon: 'error',
									title: this.$t("serverSettings.swal.invalidChannel")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
      editPreview : function (preview) {
         this.message = null;
         
         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      attObjetoMessage: function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      deletePoll : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.polls.delete"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/deletepoll',
						params: {
							idServer: this.serverS.id_s
						}
					}).then(response => {
						this.settings = response.data;

						this.$toast.fire({
							icon: 'success',
							title: this.$t("serverSettings.swal.polls.deleted")
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
		closePoll : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.polls.close"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/closepoll',
						params: {
							idServer: this.serverS.id_s
						}
					}).then(response => {
						this.settings = response.data;

						this.$toast.fire({
							icon: 'success',
							title: this.$t("serverSettings.swal.polls.closed")
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
		endPoll : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/endpoll',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
				this.settings = response.data;

				this.$toast.fire({
					icon: 'success',
					title: this.$t("serverSettings.swal.polls.closed")
				});
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		},
      addAlternative : function (e) {
         let input = e.target;

         switch (e.target.tagName) {
            case "BUTTON":
               input = e.target.previousSibling
            break;
            case "I":
               input = e.target.parentElement.previousSibling
            break;
            case "INPUT":
               input = e.target
            break;
         }

         if ((typeof input.value === 'string' || input.value instanceof String) && String(input.value).trim().length > 0) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            let newAlternative = {'id': null, 'description': input.value, 'votes': null}

            this.$axios({
               method: 'post',
               url: this.urlRest +'servicos/savepollalternative',
               headers: {
                  'Content-Type': 'application/json'
               },
               data: newAlternative,
               params: {
                  idServer: this.serverS.id_s
               }
            }).then((response) => {
               newAlternative.id = response.data
               this.settings.alternatives.push(newAlternative)
               input.value = null;

            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')

                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t("serverSettings.swal.expired")
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Error: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         }
      },
      deleteAlternative : function (index) {
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/deletePollAlternative',
            params: {
               id: this.settings.alternatives[index].id
            }
         }).then(() => {
            this.settings.alternatives.splice(index, 1)

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('getChannels')
      this.getSettings()
   }
}

</script>

<style scoped>

img {
   width: 100px;
   height: 100px;
}

</style>