<template>
   <div class="card mb-0">
      <div class="card-body p-0">
         <div class="progress rounded">
            <div class="progress-bar" role="progressbar" :style="'width:'+ percent +'%'"></div>
            <div class="progress-label">
               <div class="row">
                  <div class="col limitador">{{ alternative.description == null ? 'Unknown' : alternative.description }}</div>
                  <div class="w-max-content">
                     <span v-if="view">{{ percent.toFixed(1) }}%</span>
                     <span v-else class="cursor-pointer" @click="remove"><i class="far fa-trash font-14 text-danger mt-1"></i></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'

export default {
	name: 'Alternative',
   props: ['alternative', 'index', 'view', 'totalVotes'],
   data: function () {
      return {
         percent: 0
      }
   },
   methods: {
      remove : function () {
         this.$emit('remove', this.index)
      }
   },
   mounted() {
      if (this.totalVotes) {
         this.percent = (parseInt(this.alternative.votes) / parseInt(this.totalVotes)) * 100
      }

      Vue.nextTick(function() {
         $(".progress .progress-bar").progressbar({ display_text: "none" })
      }.bind(this));
   }
}

</script>

<style scoped>

.progress {
   height: 45px !important;
   position: relative;
}

.progress-label {
   width: 100%;
   height: 100%;
   margin-bottom: 0;
   position: absolute;
   top: 0px;
   left: 0px;
   padding: 10px 15px;
   font-size: 15px;
   line-height: 23px;
}

.progress-label .col {
   color: var(--font-fff) !important;
}

</style>